<template>
	<div class="data-content">
		<div class="top-box">
			<button class="add-btn" @click="handleEditData('add')" v-if="perms.includes('sys:contract:add')">新增</button>
			<div class="top-right-box">
				<label class="label">关键字</label>
				<el-input class="search-input" size="small" type="text" v-model="searchKey" placeholder="请输入关键字"></el-input>
				<img class="search-btn" src="@/assets/images/sousuo.png" alt="" @click="handleSearch">
				<el-button icon="el-icon-refresh-right" class="refresh-btn" @click="handleReset"></el-button>
			</div>
		</div>
		<div class="data-list-box">
			<el-table
				:data="dataList"
				:row-class-name="tableRowClassName"
				:header-cell-style="{color:'#444'}"
				style="width: 100%">
				<el-table-column
					label="NO."
					width="50">
					<template slot-scope="scope">
						<span class="no">{{scope.$index+1}}</span>
					</template>
				</el-table-column>
				<el-table-column
					prop="name"
					show-overflow-tooltip
					label="签约类型">
				</el-table-column>
				<el-table-column
					prop="modelNames"
					show-overflow-tooltip
					label="关联机型">
				</el-table-column>
				<el-table-column
					prop="remark"
					width="220"
					show-overflow-tooltip
					label="说明">
				</el-table-column>
				<el-table-column
					prop="sort"
					width="60"
					show-overflow-tooltip
					label="排序">
				</el-table-column>
				<el-table-column
					label="操作"
					width="90">
					<template slot-scope="scope">
						<button class="btn-blue" @click="handleEditData(scope.row)" v-if="perms.includes('sys:contract:update')">修改</button>
						<button class="btn-red" @click="handleDeleteData(scope.row)" v-if="perms.includes('sys:contract:delete')">删除</button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination-wrapper pa25">
				<Pagination
					:page.sync="pageNo"
					:pageSize.sync="pageSize"
					:total="total"
					@sizeChange="handleSizeChange"
					@currentChange="handleCurrentChange">
				</Pagination>
			</div>
		</div>
		<edit-data ref="editData" @getDataList="getDataList"></edit-data>
	</div>
</template>
<script>
import EditData from './components/edit-data'
import Pagination from '@/components/Pagination'
export default {
	components: {
		EditData,
		Pagination
	},
	data() {
		return {
			// 权限
			perms: JSON.parse(window.sessionStorage.getItem('perms')),
			searchKey: '',
			pageNo: 1,
			pageSize: 20,
			total: 0,
			dataList: []
		}
	},
	created() {
		this.pageNo = 1;
		this.searchKey = '';
		// 获取签约类型数据
		this.getDataList();
	},
	methods: {
		// NO.隔行变色
		tableRowClassName({row,rowIndex}) {
			if(rowIndex%2 == 1) {
				return 'blue'
			}else {
				return 'yellow'
			}
		},
		// 重置查询条件
		handleReset() {
			this.pageNo = 1;
			this.searchKey = '';
			this.getDataList();
		},
		// 获取签约类型数据
		getDataList() {
			let that = this;
			that.$request.post(
				"listContractRel",
				false,
				{
					pageNo: that.pageNo,
					pageSize: that.pageSize,
					searchKey: that.searchKey,
					code: 'contractType'
				},
				function (r) {
					if (r.code == "0") {
						that.dataList = r.data.list;
						that.total = r.data.totalCount;
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		},
		// 新增/编辑/查看
		handleEditData(data) {
			this.$refs.editData.init(data);
		},
		// 删除
		handleDeleteData(data) {
			this.$confirm('是否删除当前签约类型?', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
			}).then(() => {
				let that = this;
				that.$request.post(
					'deleteContractRel',
					true,
					{
						code: data.code,
						id: data.id,
						delFlag: 1
					},
					function (r) {
						if (r.code == "0") {
							that.getDataList();
							that.$message.success('删除成功');
						} else {
							that.$message.error(r.msg);
						}
					}
				);
			}).catch(() => {

			});
		},
		handleSearch() {
			this.pageNo = 1;
			this.getDataList();
		},
		handleSizeChange() {
			this.getDataList();
		},
		handleCurrentChange() {
			this.getDataList();
		}
	}
}
</script>
<style lang="scss" scoped>
.data-content{
	.top-box{
		height: 32px;
		margin-bottom: 20px;
	}
	.add-btn{
		display: inline-block;
		width: 88px;
		height: 32px;
		line-height: 32px;
		border: none;
		background-color: #117FFC;
		border-radius: 4px;
		color: #fff;
		font-size: 15px;
		text-align: center;
		cursor: pointer;
		margin-right: 10px;
	}
	.import-btn{
		display: inline-block;
		width: 88px;
		height: 32px;
		line-height: 32px;
		border: none;
		background-color: rgba(17, 127, 252, 0.2);
		border-radius: 4px;
		color: #117FFC;
		font-size: 15px;
		text-align: center;
		cursor: pointer;
		margin-right: 10px;
	}
	.top-right-box{
		float: right;
		.label{
			font-size: 15px;
			margin-right: 5px;
		}
		.search-input,
		.search-select{
			width: 138px;
			margin: 0 10px;
		}
		.search-btn{
			width: 32px;
    		vertical-align: middle;
			cursor: pointer;
		}
		.refresh-btn{
			width: 32px;
			height: 32px;
			border: none;
			color: #117FFC;
			padding: 0;
			background-color: rgba(17, 127, 252, 0.2);
			font-weight: bold;
			font-size: 22px;
			vertical-align: bottom;
			margin-left: 5px;
		}
	}
	.btn-blue,
	.detail-btn{
		font-size: 12px;
		font-weight: bold;
		color: #1081FD;
		text-decoration: underline;
		border: none;
		cursor: pointer;
		background-color: #fff;
		margin-right: 10px;
	}
	.btn-red{
		font-size: 12px;
		font-weight: bold;
		color: #E9515E;
		text-decoration: underline;
		border: none;
		cursor: pointer;
		background-color: #fff;
		margin-right: 10px;
	}
}
.f-r{
	float: right;
}
</style>

