<template>
	<el-dialog
		:visible.sync="visible"
		:show-close="false"
		:close-on-click-modal="false"
		:close-on-press-escape="false"
		class="role-dialog"
		width="350px">
		<span slot="title" class="dialog-title">
			<span>{{title}}</span>
			<img src="@/assets/images/close.png" alt="" @click="visible = false">
		</span>
		<el-form :model="dataForm" :rules="dataRule" label-position="top" ref="dataForm">
			<el-form-item label="签约类型" prop="name">
                <el-input v-model="dataForm.name" placeholder="签约类型" maxlength="32"></el-input>
            </el-form-item>
			<el-form-item label="关联机型分类" prop="modelIdList">
                <el-cascader
					:options="machineOptions"
					:props="machineProps"
					:show-all-levels="false"
					v-model="dataForm.modelIdList"
					clearable
					@visible-change="deviceCascaderVisableChange"
					:popper-class="deviceClass"
					class="full-width">
				</el-cascader>
            </el-form-item>
			<el-form-item label="排序" prop="sort">
                <el-input v-model="dataForm.sort" placeholder="排序" maxlength="10"></el-input>
            </el-form-item>
			<el-form-item label="类别说明" prop="remark">
                <el-input v-model="dataForm.remark" placeholder="类别说明" maxlength="512"></el-input>
            </el-form-item>
        </el-form>
		<span slot="footer" class="dialog-footer">
			<el-button class="submit-btn" @click="submit">确定</el-button>
		</span>
	</el-dialog>
</template>
<script>
import { debounce } from '@/utils/debounce'
export default {
	data() {
		return {
			title: '新增签约类型',
			visible: false,
			submitUrl: 'addContractRel',
			id: '',
			// 机型选项
			machineProps: {
				multiple: true,
				emitPath: false,
				value: 'id',
				label: 'name',
				children: 'children',
				disabled: 'disabled'
			},
			// 所有机型选项
			machineOptions: [],
			// 机型最上级与最下级映射关系
			// 因为最上级只能多选一，需要用该数据来控制
			machineRelations: [],
			deviceVisible: false,
			dataForm: {
				remark: '',
				modelIdList: [],
				sort: '',
				name: ''
			},
			dataRule: {
				name: [
					{required: true,message: '签约类型不能为空', trigger: "blur"}
				],
				modelIdList: [
					{required: true,message: '请选择关联机型分类', trigger: "change"}
				],
				sort: [
					{required: true,message: '排序不能为空', trigger: "blur"},
					{pattern: /^[0-9]*$/, message: '排序必须为整数'}
				]
			}
		}
	},
	computed: {
		deviceClass() {
			if (this.deviceVisible) return 'hide-cascader-scroll'
			return 'hide-cascader-scroll-reset'
		}
	},
	methods: {
		init(data) {
			this.visible = true;
			this.getMachineOptions();
			this.$nextTick(() => {
				this.$refs.dataForm.resetFields();
				if(data == 'add') {
					this.title = '新增签约类型';
					this.submitUrl = 'addContractRel';
				}else {
					this.title = '修改签约类型';
					this.id = data.id;
					this.dataForm.modelIdList = data.modelIdList;
					this.dataForm.sort = data.sort;
					this.dataForm.name = data.name;
					this.dataForm.remark = data.remark;
					this.submitUrl = 'updateContractRel';
				}
			})
		},
		// 获取所有机型数据
		getMachineOptions() {
			const getChildrens = (category) => {
				const children = []
				if (category.children && category.children.length > 0) {
					for (const child of category.children) {
						const c = getChildrens(child)
						children.push.apply(children, c)
					}
				} else {
					children.push(category.id)
				}

				return children
			}

			let that = this
			that.$request.post(
				"getModelDeviceSim",
				true, {},
				function (r) {
					if (r.code == "0") {
						that.machineRelations = []
						const machineOptions = r.data

						for (const category of machineOptions) {
							category.disabled = false
							const lastChildrens = getChildrens(category)
							const relation = [category.id, lastChildrens]
							that.machineRelations.push(relation)
						}

						that.machineOptions = machineOptions
					} else {
						that.$message.error(r.msg);
					}
				}
			)
		},
		// 解决cascader组件margin-bottom: -17px 失效的问题
		deviceCascaderVisableChange(visible) {
			this.deviceVisible = visible
		},
		submit:debounce(function() {
			if(this.visible == false) {
				return
			}
			// 新增
			if(this.submitUrl == 'addContractRel') {
				var submitForm = {
					name: this.dataForm.name,
					modelIdList: this.dataForm.modelIdList,
					sort: this.dataForm.sort,
					remark: this.dataForm.remark,
					code: 'contractType'
				}
			}
			// 修改
			else {
				var submitForm = {
					id: this.id,
					name: this.dataForm.name,
					modelIdList: this.dataForm.modelIdList,
					sort: this.dataForm.sort,
					remark: this.dataForm.remark,
					code: 'contractType'
				}
			}
			this.$refs['dataForm'].validate(valid => {
				if (valid) {
					let that = this;
					that.$request.post(
						that.submitUrl,
						false,
						submitForm,
						function (r) {
							if (r.code == "0") {
								that.visible = false;
								that.$emit('getDataList');
								that.$message.success('保存成功');
							} else {
								that.$message.error(r.msg);
							}
						}
					);
				} else {
					return false;
				}
			});
		},1000)
	}
}
</script>
<style lang="scss" scoped>
.dialog-title{
	display: block;
	text-align: center;
	span{
		color: #222;
		font-weight: 600;
		font-size: 20px;
	}
	img{
		width: 28px;
		float: right;
		cursor: pointer;
	}
}
.el-form-item{
	margin-bottom: 10px;
}
.submit-btn{
	width: 100%;
	background-color: #117FFC;
	color: #fff;
}
.role-dialog /deep/ .el-dialog{
	border-radius: 10px !important;
}
.role-dialog /deep/ .el-dialog__body{
	padding: 0 20px !important;
}
.role-dialog /deep/ .el-form-item__label{
	padding: 0 !important;
}
.role-dialog /deep/ .el-dialog__footer{
	padding: 20px 20px 40px !important;
}
</style>
